import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LiciDialog from "./LiciDialog";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', marginLeft: 'auto;', marginRight: 0 }}
  >
    <StarBorderIcon />

  </Box>
);

export default function LiciCard(props) {
  const { lici } = props;
  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        action={
          lici.score > 0 &&

          new Array(lici.score).fill("", 0, lici.score).map((row, index) => (

            <IconButton aria-label="settings" key={index}>
              <StarBorderIcon />
            </IconButton>
          ))
        }
        title={lici && lici.nomeOrgao + " (" + lici.cdOrgao + ")"}
        subheader={"Data Busca: " + lici._dataBusca + ", Data Abertura: " + lici.dataAbertura + "  " + lici.localidade + "/"
          + lici.uf + " - UASG: " + lici.uasg + " - Licitação: " + lici.idLicitacao}
      />
      <CardContent>
        <Typography variant="body1" component="div">
          {lici.titulo}/{lici.subTitulo}
        </Typography>
        <Typography variant="body2">
          {lici.objetivo}
        </Typography>
      </CardContent>
      <CardActions>
        <LiciDialog lici={lici} />
      </CardActions>
    </Card>
  );
}
