import React from "react";
import {
  BrowserRouter,
  Route, Routes
} from "react-router-dom";
import MainAppBar from "./pages/MainAppBar";
import BasicTable from "./pages/BasicTable";
import ConfigForm from "./pages/ConfigForm";
import ListOfCards from "./pages/dashBoard/ListOfCards";
import './App.css';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Login from "./pages/loginPage/login";
import { Grid } from "@mui/material";
import { datadogRum } from '@datadog/browser-rum';
 
datadogRum.init({
    applicationId: '8bbaf91e-e613-406c-8ba5-483044267a95',
    clientToken: 'pub73d461c7cc9602d0911930cb17202f9a',
    site: 'us5.datadoghq.com',
    service: 'poc-1',
    env: 'poc',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});


function Home() {
  useEffect(() => {
    console.log("Home")
  }, [])
  return <h2>Home</h2>;
}

function NotFound() {
  useEffect(() => {
    console.log("bla")
  }, [])
  return <h2>Não encontrado</h2>
}
function App() {
  const [busca, setBusca] = useState();
  const [wait, setWait] = useState(false);
  const [validate, setValidate] = useState(localStorage.getItem("loggedInA"))
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <BrowserRouter>
      {/*  */}
      {
        validate ?
          <Grid>
          <MainAppBar setBusca={setBusca} setValidate={setValidate} validate={validate}/>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/config" element={<ConfigForm setWait={setWait} />} />

              <Route path="/board" element={<ListOfCards busca={busca} setWait={setWait} />} />

              <Route path="/lista" element={<BasicTable busca={busca} setWait={setWait} />} />

              {/* <Route path="/*" element={<NotFound />} /> */}

            </Routes>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={wait}
              onClick={() => setWait(false)}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid> :
          <Grid>
            <Login setValidate={setValidate} />
          </Grid>
      }


    </BrowserRouter>
  );
}



export default App;
