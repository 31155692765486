import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Service from "../services/Service";
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';


function AddKeyField(props) {
  const { addItem, handleDelete } = props;
  const [val, setVal] = useState("");
  const inputRef = useRef(null);

  const handleClick = () => {
    if (addItem) {
      addItem(val);
      setVal("");

      inputRef.current.firstChild.focus();
      inputRef.current.firstChild.placeholder = '';

    }
  }
  const handleOnChange = (e) => {
    console.log("-->", e.target.value)
    setVal(e.target.value);
  }

  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton> */}
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        // placeholder="AaaA"
        inputProps={{ 'aria-label': 'search google maps' }}
        value={val}
        autoFocus
        ref={inputRef}
        onChange={handleOnChange}
      />

      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
        onClick={handleClick}
      >
        <AddCircleOutlinedIcon />
      </IconButton>
    </Paper>
  );
}

export default function ConfigForm(props) {
  const { setWait } = props;
  const [key, setKey] = useState([]);
  const [emails, setEmails] = useState([]);
  const [keyImportante, setKeyImportante] = useState([]);
  const [config, setConfig] = useState();
  const [urlRef, setUrlRef] = useState("");
  const [erro, setErro] = useState();

  useEffect(() => {
    console.log("1")
    Service.GET("/api/config/list", setWait).then(response => {
      console.log("-->", response);

      if (response && response.length > 0) {
        setConfig(response[0]);
        setKeyImportante(response[0].keyImportante)
        setKey(response[0].key);
        if(response[0].emails)
          setEmails(response[0].emails);
        setUrlRef(response[0].urlRef);
      }

    }).catch(error => {
      if (error.message === 'duplicado') return;
      console.log("2")
      console.error("3", error);
      setErro(error.statusText);
    });
  }, []);

  const handleDelete = (id) => {
    console.log(">>delete<<<", id);
    const val = key[id];
    let arr = [...key];
    arr = arr.filter(item => item !== val);
    setKey(arr);
  }
  const handleDeleteImportante = (id) => {
    console.log(">>delete111<<<", id);
    const val = keyImportante[id];
    let arr = [...keyImportante];
    arr = arr.filter(item => item !== val);
    setKeyImportante(arr);
  }
  const handleDeleteEmail = (id) => {
    console.log(">>delete<<<", id);
    const val = emails[id];
    let arr = [...emails];
    arr = arr.filter(item => item !== val);
    setEmails(arr);
  }
  const addItem = (item) => {
    console.log("adddd", item);
    let dado = [...key];
    dado.push(item);
    setKey(dado);
  }

  const addItemImportante = (item) => {
    if(!keyImportante){
      setKeyImportante([item]);
    }else{
      let dado = [...keyImportante];
      dado.push(item);
      setKeyImportante(dado);
    }
  }
  const addItemEmail = (item) => {
    console.log("adddd", item);
    let dado = [...emails];
    dado.push(item);
    setEmails(dado);
  }
  const handleSave = () => {
    let dado = { ...config };
    dado.urlRef = urlRef;
    dado.key = key;
    dado.keyImportante = keyImportante;
    dado.emails = emails;

    Service.POST('/api/config', dado).then(resp => {
      // setWait(false);
      // sair();
      console.log(">>>salve<<<", resp)
      setConfig(resp)
    }).catch(error => {
      console.error(error);
      // setWait(false);
      // setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
    })
  }
  return (
    <Container maxWidth="xl" sx={{ p: 4 }}>
      <Grid container spacing={2}>
        {erro &&
          <Grid item xs={12}>
            <Alert severity="error">{erro}</Alert>
          </Grid>
        }
        <Grid item xs={10}>
          <TextField
            required
            id="outlined-required"
            label="URL de referencia"
            fullWidth sx={{ m: 1 }}
            defaultValue="https://www.licinet.com.br/xml/GerarEmailDoCliente.php?edicao=Licinet+Tarde+13/04/2022&cliente=62745&chave=e&telemarketing=0&nome=Very Tecnologia Ltda Epp&email=comercial@verytecnologia.com.br"
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" endIcon={<SaveAltIcon />} onClick={handleSave}>
            Salvar
          </Button>
        </Grid>

        <Grid item xs={4}>
          <InputLabel  >
            Chave de Busca
          </InputLabel>
          <AddKeyField addItem={addItem} handleDelete={handleDelete} label={"Chave busca licinetes"} />
        </Grid>

        <Grid item xs={4}>
          <InputLabel  >
            Chave de Busca importante
          </InputLabel>
          <AddKeyField addItem={addItemImportante} handleDelete={handleDeleteImportante} label={"Chave busca licinetes"} />
        </Grid>
        <Grid item xs={4}>
          <InputLabel  >
            Email
          </InputLabel>
          <AddKeyField addItem={addItemEmail} handleDelete={handleDeleteEmail} label={"email"} />
        </Grid>

        <Grid item xs={4}>
          <Paper sx={{ maxWidth: 400 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >
              {key.map((val, index) => (
                <ListItem key={index}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={val}
                  // secondary={'Secondary text'}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper sx={{ maxWidth: 400 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >
              {keyImportante?.map((val, index) => (
                <ListItem key={index}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteImportante(index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={val}
                  // secondary={'Secondary text'}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper sx={{ maxWidth: 400 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >
              {emails.map((val, index) => (
                <ListItem key={index}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteEmail(index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={val}
                  // secondary={'Secondary text'}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
