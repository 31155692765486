import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import LiciCard from "./LiciCard"
import Service from "../../services/Service";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DataGrupoPanel from "./DataGrupoPanel";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';

const periodoDias = 4;
let auxtime = 0;

export default function ListOfCards(props) {
    const { busca, setWait } = props;
    const [erro, setErro] = useState();
    const [checked, setChecked] = useState(true);
    const [lici, setLici] = useState([]);
    const [filtrado, setFiltrado] = useState([]);
    const [dataGrupo, setDataGrupo] = useState();
    const [filtroGrupo, setFiltroGrupo] = useState(true);
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());

    useEffect(() => {
        setErro();
        // console.log(">>>>>>1")
        Service.GET("/api/liciNetComScore", setWait).then(response => {
            if (response && response.length > 0) {
                setLici(response);
                montaDataGroup(response);
            }
        }).catch(error => {
            if (error.message === 'duplicado') return;
            setErro("erro inesperado: " + error.statusText);
        });
    }, []);

    useEffect(() => {
        const arr = filtraDado();
        setFiltrado(arr);
    }, [checked]);

    useEffect(() => {
        const arr = filtraDado();
        setFiltrado(arr);
    }, [lici]);
    useEffect(() => {
        // if( busca ){
        const arr = filtraDado();
        setFiltrado(arr);
        // }
    }, [busca]);


    useEffect(() => {
        const arr = filtraDado();
        setFiltrado(arr);
    }, [filtroGrupo]);




    const montaDataGroup = (arr) => {
        let ret = [];
        for (let x in arr) {
            const item = ret.find(r => r === arr[x]._dataBusca)
            if (!item) {
                ret.push(arr[x]._dataBusca);
            }
        }
        if (ret.length > 1) {
            ret.push("Todos")
            setDataGrupo(ret);
        } else {
            setDataGrupo();
        }
    }
    function dataAtualFormatada(data) {
        var dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(),
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }
    const handleOnClick = (event) => {
        setChecked(event.target.checked);
    }
    const ordenaDado = (arr) => {

        return arr.sort((a, b) => {
            if (a._ordem > b._ordem) {
                return 1;
            }
            if (a._ordem < b._ordem) {
                return -1;
            }
            if (a._periodoBusca > b._periodoBusca) {
                return -1;
            }
            if (a._periodoBusca < b._periodoBusca) {
                return 1;
            }

            if (a.score > b.score) {
                return -1;
            }
            if (a.score < b.score) {
                return 1;
            }
            return 0;
        })
    }
    const filtraDado = () => {

        if (filtroGrupo && lici.length > 0) {
            let arr = []
            for (let x in lici) {
                let item = lici[x];
                item._ordem = crateOrdemTmp(item);
                if (checked && item.score === 0) continue;
                arr.push(item);
            }

            if (filtroGrupo) {
                arr = arr.filter(item => {
                    if (filtroGrupo === true) {
                        return true;
                    }
                    if (filtroGrupo === "Todos") {
                        return true;
                    }
                    if (item._dataBusca === filtroGrupo) {
                        return true;
                    }
                    return false;
                });
            }
            if (busca && busca.length > 0) {
                arr = arr.filter(item => {
                    for (let x in item) {
                        if (typeof item[x] === 'string') {
                            if (item[x].toUpperCase().includes(busca.toUpperCase())
                            ) {
                                return true;
                            }
                        } else {

                            if (item[x] == busca) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
            }

            const arrOrdenado = ordenaDado(arr);
            return arrOrdenado;
        }
        return [];
    }
    function crateOrdemTmp(item) {
        if (item.uf === 'DF') return 0;
        if (item.uf === 'SP') return 1;
        if (item.uf === 'RJ') return 2;
        if (item.uf === 'PI') return 3;
        if (item.uf === 'PA') return 4;

        return 100;
    }


    const handleOnChangeInicio = async (newValue) => {
        let valPeriodo = new Date(newValue);
        valPeriodo.setDate(newValue.getDate() + periodoDias);
        let dtFim = new Date(dataFim);;


        setDataInicio(newValue);
        auxtime++;
        await timeout(400);
        if (auxtime <= 1) {
            auxtime = 0;

            if (valPeriodo <= dataFim) {
                dtFim = valPeriodo;
                setDataFim(valPeriodo)
            } else if (newValue > dataFim) {
                dtFim = newValue;
                setDataFim(newValue);
            }

            buscaLiciNetPeriodo(newValue, dtFim);
        } else {
            auxtime--;
        }

    }
    const handleOnChangeFim = (newValue) => {
        // console.log(newValue);
        setDataFim(newValue);
        buscaLiciNetPeriodo(dataInicio, newValue);

    }
    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    function buscaLiciNetPeriodo(inicio, fim) {
        const dtI = dataAtualFormatada(inicio);
        const dtF = dataAtualFormatada(fim);
        // console.log("buscar", dtI, dtF)
        Service.GET("/api/liciNetPeriodo?dataInicial=" + dtI + "&dataFinal=" + dtF, setWait).then(response => {
            // console.log(response)
            if (response && response.length > 0) {
                // for (let x in response) {
                //     console.log(response[x]._dataBusca);
                // }
                let aResp = response;
                aResp.reverse()
                setLici(aResp);
                montaDataGroup(response);
            } else {
                setLici([]);
                montaDataGroup([]);
            }
        }).catch(error => {
            if (error.message === 'duplicado') return;
            setErro("erro inesperado: " + error.statusText);
        });
    }

    function calcuMaxDate() {
        let dateC = new Date(dataInicio);
        dateC.setDate(dateC.getDate() + periodoDias);
        if (dateC < new Date()) {
            return dateC;
        }
        return new Date();
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} >
            <Container maxWidth="xl" sx={{ p: 4 }}>
                {erro &&
                    <Alert severity="error">{erro}</Alert>
                }
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked onChange={handleOnClick} />} label="Apenas relevantes" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        {dataGrupo &&
                            <DataGrupoPanel dataGrupo={dataGrupo} setFiltroGrupo={setFiltroGrupo} />
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            // inputFormat="dd.MM.yyyy"
                            inputFormat="DD-MM-YYYY"
                            label="de"
                            value={dataInicio}
                            onChange={handleOnChangeInicio}
                            maxDate={new Date()}
                        // slotProps={{
                        //     textField: {
                        //         fullWidth: true,
                        //         variant: 'outlined',
                        //         error: !!erroData.inicial.erro,
                        //         helperText: erroData.inicial.message,
                        //     },
                        // }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            error
                            label="até"
                            value={dataFim}
                            onChange={handleOnChangeFim}
                            maxDate={calcuMaxDate()}
                            minDate={dataInicio}
                        // slotProps={{
                        //     textField: {
                        //         fullWidth: true,
                        //         variant: 'outlined',
                        //         error: !!erroData.final.erro,
                        //         helperText: erroData.final.message,
                        //     },
                        // }}
                        />
                    </Grid>
                </Grid>
                <List sx={{
                    width: '100%',
                    // maxWidth: 360, 
                    bgcolor: 'background.paper'
                }}>
                    {filtrado.map((item, index) => (
                        <ListItem alignItems="flex-start" key={index}>
                            <LiciCard lici={item} />
                        </ListItem>
                    ))}
                </List>
            </Container>
        </LocalizationProvider>
    );
}
