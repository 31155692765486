import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import DataGrupoPanel from "./DataGrupoPanel";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';
import DataGrupoPanel from './dashBoard/DataGrupoPanel';
import Service from '../services/Service';
import { Container, DialogContent, FormControlLabel, FormGroup, Grid, IconButton, Switch, TablePagination, Typography, makeStyles } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const periodoDias = 4;
/*
data do licinet ->  _dataBusca
órgão, -> nomeOrgao (code)
uasg, -> uasg
descrição, -> objetivo
data de abertura -> dataAbertura
*/
const columns = [
  { id: '_dataBusca', label: 'Data', minWidth: 30 },
  { id: 'nomeOrgao', label: 'Órgão', minWidth: 30 },
  { id: 'uasg', label: 'uasg', minWidth: 170 },
  { id: 'objetivo', label: 'Descrição', minWidth: 40 },
  { id: 'dataAbertura', label: 'Abertura', minWidth: 40 },
  // {
  //   id: 'population',
  //   label: 'Population',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'size',
  //   label: 'Size\u00a0(km\u00b2)',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'density',
  //   label: 'Density',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];



let auxtime = 0;


export default function BasicTable(props) {
  const { busca, setWait } = props;
  const [erro, setErro] = useState();
  const [checked, setChecked] = useState(true);
  const [lici, setLici] = useState([]);
  const [filtrado, setFiltrado] = useState([]);
  const [dataGrupo, setDataGrupo] = useState();
  const [filtroGrupo, setFiltroGrupo] = useState(true);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [liciItem, setLiciItem] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setErro();
    // console.log(">>>>>>1")
    Service.GET("/api/liciNetComScore", setWait).then(response => {
      if (response && response.length > 0) {
        setLici(response);
        montaDataGroup(response);
      }
    }).catch(error => {
      if (error.message === 'duplicado') return;
      setErro("erro inesperado: " + error.statusText);
    });
  }, []);

  useEffect(() => {
    const arr = filtraDado();
    setFiltrado(arr);
  }, [checked]);

  useEffect(() => {
    const arr = filtraDado();
    // console.log("lici", arr)
    setFiltrado(arr);
  }, [lici]);
  useEffect(() => {
    // if( busca ){
    const arr = filtraDado();
    setFiltrado(arr);
    // }
  }, [busca]);


  useEffect(() => {
    const arr = filtraDado();
    setFiltrado(arr);
  }, [filtroGrupo]);




  const montaDataGroup = (arr) => {
    let ret = [];
    for (let x in arr) {
      const item = ret.find(r => r === arr[x]._dataBusca)
      if (!item) {
        ret.push(arr[x]._dataBusca);
      }
    }
    if (ret.length > 1) {
      ret.push("Todos")
      setDataGrupo(ret);
    } else {
      setDataGrupo();
    }
  }
  function dataAtualFormatada(data) {
    var dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(),
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }
  const handleOnClick = (event) => {
    setChecked(event.target.checked);
  }
  const ordenaDado = (arr) => {

    return arr.sort((a, b) => {
      if (a._ordem > b._ordem) {
        return 1;
      }
      if (a._ordem < b._ordem) {
        return -1;
      }
      if (a._periodoBusca > b._periodoBusca) {
        return -1;
      }
      if (a._periodoBusca < b._periodoBusca) {
        return 1;
      }

      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    })
  }
  const filtraDado = () => {

    if (filtroGrupo && lici.length > 0) {
      let arr = []
      for (let x in lici) {
        let item = lici[x];
        item._ordem = crateOrdemTmp(item);
        if (checked && item.score === 0) continue;
        arr.push(item);
      }

      if (filtroGrupo) {
        arr = arr.filter(item => {
          if (filtroGrupo === true) {
            return true;
          }
          if (filtroGrupo === "Todos") {
            return true;
          }
          if (item._dataBusca === filtroGrupo) {
            return true;
          }
          return false;
        });
      }
      if (busca && busca.length > 0) {
        arr = arr.filter(item => {
          for (let x in item) {
            if (typeof item[x] === 'string') {
              if (item[x].toUpperCase().includes(busca.toUpperCase())
              ) {
                return true;
              }
            } else {

              if (item[x] == busca) {
                return true;
              }
            }
          }
          return false;
        });
      }

      const arrOrdenado = ordenaDado(arr);
      return arrOrdenado;
    }
    return [];
  }
  function crateOrdemTmp(item) {
    if (item.uf === 'DF') return 0;
    if (item.uf === 'SP') return 1;
    if (item.uf === 'RJ') return 2;
    if (item.uf === 'PI') return 3;
    if (item.uf === 'PA') return 4;

    return 100;
  }


  const handleOnChangeInicio = async (newValue) => {
    let valPeriodo = new Date(newValue);
    valPeriodo.setDate(newValue.getDate() + periodoDias);
    let dtFim = new Date(dataFim);;


    setDataInicio(newValue);
    auxtime++;
    await timeout(400);
    console.log(">>>>" + auxtime);
    if (auxtime <= 1) {
      auxtime = 0;

      if (valPeriodo <= dataFim) {
        dtFim = valPeriodo;
        setDataFim(valPeriodo)
      } else if (newValue > dataFim) {
        dtFim = newValue;
        setDataFim(newValue);
      }

      buscaLiciNetPeriodo(newValue, dtFim);
      console.log("OK" + auxtime);
    } else {
      auxtime--;
    }

  }
  const handleOnChangeFim = (newValue) => {
    // console.log(newValue);
    setDataFim(newValue);
    buscaLiciNetPeriodo(dataInicio, newValue);

  }

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  function buscaLiciNetPeriodo(inicio, fim) {
    const dtI = dataAtualFormatada(inicio);
    const dtF = dataAtualFormatada(fim);
    console.log("buscar", dtI, dtF)
    Service.GET("/api/liciNetPeriodo?dataInicial=" + dtI + "&dataFinal=" + dtF, setWait).then(response => {
      // console.log(response)
      if (response && response.length > 0) {
        // for (let x in response) {
        //     console.log(response[x]._dataBusca);
        // }
        let aResp = response;
        aResp.reverse()
        setLici(aResp);
        montaDataGroup(response);
      } else {
        setLici([]);
        montaDataGroup([]);
      }
    }).catch(error => {
      if (error.message === 'duplicado') return;
      setErro("erro inesperado: " + error.statusText);
    });
  }

  function calcuMaxDate() {
    let dateC = new Date(dataInicio);
    dateC.setDate(dateC.getDate() + periodoDias);
    if (dateC < new Date()) {
      return dateC;
    }
    return new Date();
  }

  const handleClickOpen = (e) => {
    setLiciItem(e);
    // console.log(e)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} >
      <Container maxWidth="xl" sx={{ p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel control={<Switch defaultChecked onChange={handleOnClick} />} label="Apenas relevantes" />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            {dataGrupo &&
              <DataGrupoPanel dataGrupo={dataGrupo} setFiltroGrupo={setFiltroGrupo} />
            }
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              // inputFormat="dd.MM.yyyy"
              inputFormat="DD-MM-YYYY"
              label="de"
              value={dataInicio}
              onChange={handleOnChangeInicio}
              maxDate={new Date()}
            // slotProps={{
            //     textField: {
            //         fullWidth: true,
            //         variant: 'outlined',
            //         error: !!erroData.inicial.erro,
            //         helperText: erroData.inicial.message,
            //     },
            // }}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              error
              label="até"
              value={dataFim}
              onChange={handleOnChangeFim}
              maxDate={calcuMaxDate()}
              minDate={dataInicio}
            // slotProps={{
            //     textField: {
            //         fullWidth: true,
            //         variant: 'outlined',
            //         error: !!erroData.final.erro,
            //         helperText: erroData.final.message,
            //     },
            // }}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 700 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead >
                    <TableRow sx={{
                      "& th": {
                        color: "#fff",
                        backgroundColor: '#1976d2'
                      }
                    }} >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        key={'endkey'}
                      // align={column.align}
                      // style={{ minWidth: column.minWidth }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtrado
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                            <TableCell
                              key={'endkey'}
                            // align={column.align}
                            // style={{ minWidth: column.minWidth }}
                            >
                              <IconButton aria-label="settings" onClick={() => handleClickOpen(row)} >
                                <VisibilityIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={filtrado.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth='lg'
        >

          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Todos os dados
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {liciItem && Object.entries(liciItem).map((item, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={2}>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="subtitle2"
                    // gutterBottom
                    color="text.primary"
                  >
                    {item[0]}:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="caption"
                    // gutterBottom
                    color="text.primary"
                  >
                    {item[1]}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </BootstrapDialog>
        {/* <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Dessert (100g serving)</TableCell>
                <TableCell align="right">Calories</TableCell>
                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                <TableCell align="right">Protein&nbsp;(g)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                  <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Container>
    </LocalizationProvider>
  );
}
