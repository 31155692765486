const API_BASE_URL = 'http://localhost:8080';

let lastCall = "";
const Service = {
    POST: function (url, dado) {
        const val = {
            url: API_BASE_URL + url,
            dado: dado
        }

        return fetch(url, {
            method: "POST",
            body: JSON.stringify(dado),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(r => {
            if (r.ok) {
                return r.json();
            } else {
                return r.json().then(erro => {
                    console.error("---->", erro);
                    if (erro.status === 500) {
                        if (erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired")
                        ) {
                            Service.gotoLogin();
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        } else {
                            throw (erro && erro.message) ? erro.message : "";
                        }
                    } else {
                        throw erro;
                    }
                });
            }
        });
    },

    GET: function (url, setWait) {
        // console.log("2");
        if( setWait) setWait(true);
        if (lastCall === url) return new Promise(function (resolve, reject) {
            setWait(false);
            reject(Error("duplicado"));
        })
        lastCall = url
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(r => {
            if (r.ok) {
                // console.log("3");
                if( setWait) setWait(false);
                return r.json();
            } else {
                // console.log("4");
                if( setWait) setWait(false);
                if (typeof r === "object") {
                    throw r;
                }
                return r.json().then(erro => {

                    console.error("Erro da requisicao: ", erro);
                    if (erro.status === 500) {
                        if (erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired")
                        ) {
                            Service.gotoLogin();
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        } else {
                            throw (erro && erro.message) ? erro.message : "";
                        }
                    } else {
                        throw erro;
                    }
                });
            }
        });
    },

};


export default Service;