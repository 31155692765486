import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useState } from 'react';



export default function DataGrupoPanel(props) {
    const {dataGrupo, setFiltroGrupo} = props;
    const [ativo, setAtivo] = useState();

    
    function handleClick(event) {
        event.preventDefault();
        if( event.target.name){ 
            setAtivo(event.target.name);
            setFiltroGrupo(event.target.name);
        }
      }
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
          {dataGrupo && dataGrupo.map((dg, index)=>(
            <Link 
                underline="hover" 
                color={ dg === ativo? "text.primary":"inherit" }
                href="#"
                key={index}
                name={dg}
                // aria-current="page"
            >
                {dg}
            </Link>
          ))}
        
        {/* <Link
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Core
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          href="/material-ui/react-breadcrumbs/"
          aria-current="page"
        >
          Breadcrumbs
        </Link> */}
      </Breadcrumbs>
      </div>
  );
}
