import { Alert, Button, Container, Grid, TextField } from "@mui/material";
import React, { useState } from "react";

const login = [
    { usuario: 'licitacao@verytecnologia.com.br', senha: 'Q1w2e3r$' },
    // { usuario: 'teste1', senha: 'teste1'}
]

export default function Login(props) {
    const { setValidate } = props;
    const [usuario, setUsuario] = useState("")
    const [senha, setSenha] = useState("")
    const [error, setError] = useState(false)

    const handlerLogin = (e) => {
        const user = login.find((login) => usuario === login.usuario && senha === login.senha)
        if (user) {
            localStorage.setItem("loggedInA", "true");

            setValidate(true);
        } else {
            setError(true)
        }

    }


    return (
        <Container maxWidth="xl" style={containerStyle}>
            {error &&
                <Grid >

                    <Alert severity="error">Usuario ou senha invalido</Alert>
                </Grid>
            }


            <Grid item xs={2} style={inputStyle}>
                <TextField
                    required
                    label="Login"
                    value={usuario}
                    onChange={(e) => setUsuario(e.target.value)}
                />
            </Grid>
            <Grid item xs={2} style={inputStyle}>
                <TextField
                    required
                    label="Senha"
                    type="password"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                />
            </Grid>
            <Button onClick={handlerLogin}>Logar</Button>
        </Container>

    )
}

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
};

const inputStyle = {
    margin: '5px',
    padding: '0px',
};